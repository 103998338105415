import api from "./api";

const OrdenTrabajoService = {};

let ODT = {
    ot0_codigo: 0,
    ot0_tipcmp: "Sin definir",
    ot0_tipfor: "Sin definir",
    ot0_sucurs: 0,
    ot0_numero: 0,
    ot0_fecemi: "Sin definir",
    ot0_fecven: "Sin definir",
    ot0_fecalt: "Sin definir",
    ot0_descli: 0,
    ot0_descab: 0,
    ot0_impdes: 0,
    ot0_impnet: 0,
    ot0_imptot: 0,
    ot0_impint: 0,
    ot0_impbru: 0,
    ot0_exento: 0,
    ot0_impiva: 0,
    ot0_impiib: 0,
    ot0_impper: 0,
    ot0_poriib: 0,
    ot0_observ: "Sin definir",
    ot0_estado: 0,
    ot0_cotiza: 0,
    ote_descri: "Sin definir",
    mon_codigo: 0,
    mon_descri: "Sin definir",
    mon_signo: "Sin definir",
    tiv_codigo: 0,
    tiv_Descri: "Sin definir",
    ot1_item: 0,
    ot1_descri: "Sin definir",
    ot1_cantid: 0,
    ot1_precio: 0,
    ot1_prenet: 0,
    ot1_exento: 0,
    ot1_porint: 0,
    ot1_impint: 0,
    ot1_descue: 0,
    ot1_poriva: 0,
    ot1_impiva: 0,
    ot1_porsta: 0,
    ot1_impsta: 0,
    ot1_pordes: 0,
    art_codigo: 0,
    art_articu: "Sin definir",
    art_descri: "Sin definir",
    cli_codigo: 0,
    cli_razsoc: "Sin definir",
    ven_codigo: 0,
    ven_razsoc: "Sin definir"
};

OrdenTrabajoService.ODT = ODT;

OrdenTrabajoService.getAll = async function() {
    return api.get('/OrdenesTrabajoDef/').then(res => res.data);
}

OrdenTrabajoService.consultarPorCodigo = async function(id) {
    return api.get(`/OrdenesTrabajoDef/${id}`).then(res => res.data);
}

OrdenTrabajoService.set = function(odt) {
    if (odt !== null && typeof odt === "object") {
        localStorage.odt = JSON.stringify(odt);
    }
}

OrdenTrabajoService.get = function () {
    return JSON.parse(localStorage.odt);
}

export default OrdenTrabajoService;