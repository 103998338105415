<template>
    <section>
        <loading v-bind:dialog="isLoading"></loading>
        <alert
          v-bind:tipo="notificacion.tipo"
          v-bind:mensaje="notificacion.mensaje"
        ></alert>
        <v-tabs
            v-model="active"
            color="yellow darken-4"
            dark
            slider-color="white"
        >
            <v-tab :key="'generales'" ripple>
                General
            </v-tab>
            <v-tab-item :key="'generales'">
                <v-flex xs12>
                    <v-card color="grey lighten-3">
                        <v-card-text>
                            <v-layout row wrap>
                                <v-flex grow>
                                    Fec. Ven.: {{ odts[0].ot0_fecven | fecha }}
                                </v-flex>
                                <v-flex grow>
                                    Vendedor: {{ odts[0].ven_razsoc }}
                                </v-flex>
                                <v-flex grow>
                                    N° OT: {{ odts[0].ot0_codigo }}
                                </v-flex>
                                <v-flex grow>
                                    Estado: {{ odts[0].ote_descri }}
                                </v-flex>
                                <v-flex grow>
                                    Observ.: {{ odts[0].ot0_observ }}
                                </v-flex>
                            </v-layout>
                        </v-card-text>
                    </v-card>
                </v-flex>
            </v-tab-item>
            <v-tab :key="'cliente'" ripple>
                Cliente
            </v-tab>
            <v-tab-item :key="'cliente'">
                <v-flex xs12>
                    <v-card color="grey lighten-3">
                        <v-card-text>
                            <v-layout row wrap>
                                <v-flex grow>
                                    Razón Social: {{ Cliente.cli_razsoc }}
                                </v-flex>
                                <v-flex grow>
                                    CUIT/DNI: {{ Cliente.cli_cuit }}
                                </v-flex>
                                <v-flex grow>
                                    Domicilio: {{ Cliente.cli_direcc }}
                                </v-flex>
                                <v-flex grow>
                                    Teléfono: {{ Cliente.cli_telefo }}
                                </v-flex>
                            </v-layout>
                        </v-card-text>
                    </v-card>
                </v-flex>
            </v-tab-item>
            <v-tab :key="'items'" ripple>
                Items
            </v-tab>
            <v-tab-item :key="'items'">
                <v-flex xs12>
                    <v-card color="grey lighten-3">
                        <v-card-text>
                            <template v-for="(odt, index) in odts">
                                <v-layout 
                                    row 
                                    wrap 
                                    :key="odt.art_codigo"
                                >
                                    <v-flex xs12 sm2>
                                        Art.: {{ odt.art_codigo }}
                                    </v-flex>
                                    <v-flex xs12 sm1>
                                        Cant.: {{ odt.ot1_cantid }}
                                    </v-flex>
                                    <v-flex xs12 sm2>
                                        Cod. Corto: {{ odt.art_articu }}
                                    </v-flex>
                                    <v-flex xs12 sm7>
                                        Descripción: {{ odt.ot1_descri }}
                                    </v-flex>
                                </v-layout>
                                <v-divider :key="index+1"></v-divider>
                            </template>
                        </v-card-text>
                    </v-card>
                </v-flex>
            </v-tab-item>
        </v-tabs>
        <div class="text-xs-center mt-3">
            <v-btn to="/Pedidos/Consultar">Atras</v-btn>
        </div>
    </section>
</template>

<script>
import odtServices from "@/services/OrdenesTrabajoServices";
import ClienteServices from "@/services/ClienteServices";
import alert from "@/components/alert/alert.vue";
import loading from "@/components/share/Loading.vue";
import utils from '@/plugins/Utils.js';

export default {
    name: 'Pedido',
    mixins: [utils.mixins],
    components: { alert, loading },
    data() {
        return {
            active: null,
            isLoading: false,
            notificacion: { mensaje: "", tipo: "error" },
            odts: [odtServices.ODT],
            Cliente: ClienteServices.current(),
        };
    },
    created() {
        this.obtenerPedido(this.$route.params.idodt);
    },
    methods: {
        obtenerPedido(id) {
            this.isLoading = true;
            odtServices.consultarPorCodigo(id)
            .then(res => {
                this.odts = res;
                if (this.odts.length < 0) {
                    this.$router.push({ name: 'NotFound' });
                }
            })
            .catch(err => {
                this.notificacion.mensaje =
                    "Ocurrio un error al intentar recuperar los registros.";
                if (err.response && err.response.data.hasOwnProperty('errorMessage')) {
                    this.notificacion.mensaje = err.response.data.errorMessage;
                }
            })
            .finally(() => {
                this.isLoading = false;
            });
        },
    }
}
</script>