<template>
  <v-container grid-list-xl fluid>
    <listarCliente v-bind:titulo="'Detalles del pedido'"></listarCliente>
    <verPedido></verPedido>
    </v-container>
</template>

<script>
import listarCliente from "@/components/cliente/ListarClientes.vue";
import verPedido from '@/components/pedidos/verPedido.vue';
export default {
  name: "PedidosVer",
  components: { verPedido, listarCliente },
};
</script>
